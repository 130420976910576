import { db, customer_collection } from "@/firebase";

// function get_user(uid) {
//   const user_data = fb.user_data.doc(uid).get();
//   console.log(user_data);
//   return user_data;
// }

function get_client_list() {
  return customer_collection.get();
}

function get_collection(client, relPath) {
  let path = `customers/${client}/${relPath}`;
  console.log(path);
  return db.collection(path);
}

export { get_client_list, get_collection };
