import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyB_UfOy9uRdArhuIq4DaqFhWeX-pgSJHP8",
  authDomain: "cgcaci21.firebaseapp.com",
  databaseURL: "https://cgcaci21.firebaseio.com",
  projectId: "cgcaci21",
  storageBucket: "cgcaci21.appspot.com",
  messagingSenderId: "595065889262",
  appId: "1:595065889262:web:90fac884b0452a89b56eff",
  measurementId: "G-M0K5LQT39Q"
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }
const rtdb = firebase.database();
const auth = firebase.auth();
const fbFunctions = firebase.functions();

// collection references
const points = db.collection("points");
const admin_collections = db.collection("admin");
const user_data = db.collection("users");
const point_results = db.collection("point_results");
const customer_collection = db.collection("customers");
const roomsRef = rtdb.ref("room_awardees");
const firestore = firebase.firestore;
const saved_reactions_ref = rtdb.ref("/reactions/saved_clips");
console.log(roomsRef);
// export utils/refs
export {
  db,
  auth,
  points,
  admin_collections,
  user_data,
  fbFunctions,
  point_results,
  rtdb,
  customer_collection,
  roomsRef,
  firebaseConfig,
  firestore,
  saved_reactions_ref
};
