import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import { auth } from "./firebase";
import Clipboard from "v-clipboard";

Vue.use(VueSkycons, {
  color: "#1e88e5"
});

Vue.use(Clipboard);

Vue.config.productionTip = false;
Vue.use(Vuebar);
let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      vuetify,
      store,
      router,
      render: h => h(App)
    }).$mount("#app");
  }
  if (user) {
    console.log(user.email);
    console.log(user.password);
    // store.dispatch("log_out");
    if (store.state.user_logged_in === false) {
      store.dispatch("fetchUserProfile", user);
      store.commit("set_logged_in_state", true);
    } else {
      store.commit("");
    }
  }
});
