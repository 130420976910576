function get_additional_fields(userData) {
  let standard_fields = [
    "first_name",
    "last_name",
    "event_email",
    "email",
    "password",
    "event_attendee_id"
  ];
  let non_standard_fields = {};
  for (let i in userData) {
    if (!standard_fields.includes(i)) {
      non_standard_fields[i] = userData[i];
    }
  }
  return non_standard_fields;
}

export { get_additional_fields };
