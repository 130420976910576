<template>
  <v-app
    id="materialpro"
    :class="
      `${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`
    "
  >
    <!-- <v-main> -->
    <router-view />
    <!-- </v-main> -->
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {}
};
</script>
