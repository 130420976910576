import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
// import Home from "@/views/Home";
import { auth } from "@/firebase";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "home",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          name: "Home",
          path: "home",
          component: () => import("@/views/Home"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Users",
          path: "admin/users",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/admin/AdminUserLayout"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Groups",
          path: "admin/groups",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/admin/AdminGroupManager"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "PointViewer",
          path: "admin/points",
          component: () =>
            import(
              /* webpackChunkName: "pointViewer" */ "@/views/admin/AdminPointLayout"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Point Manager",
          path: "admin/point_manager",
          component: () =>
            import(
              /* webpackChunkName: "pointManager" */ "@/views/admin/AdminPointManagerLayout"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Locations",
          path: "admin/locations",
          component: () =>
            //views/admin/locations
            import(
              /* webpackChunkName: "about" */ "@/views/admin/AdminLocationLayout"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Client List",
          path: "admin/clients",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/admin/customers"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "AV Control Panel",
          path: "games/av_control_panel",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/av_control_panel"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Leaderboards",
          path: "games/leaderboards",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/leaderboards"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Visit To Winit",
          path: "games/visittowinit",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/visit_to_winit"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Easter Egg Hunt",
          path: "games/easteregghunt",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/easter_egg_hunt"
            ),
          meta: {
            requiresAuth: true
          }
        },

        {
          name: "Cocktail Directory",
          path: "games/cocktaildirectory",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/cocktail_directory"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Quiz Game",
          path: "games/quizgame",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/games/quiz_game"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Social Feed",
          path: "games/socialfeed",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/games/social_feed"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Social Feed Manager",
          path: "games/socialfeed/:id",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/admin/AdminSocialStreamManager"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Reactions",
          path: "games/reactions",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "@/views/games/reaction_system"
            ),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: "/Login",
      name: "Login",
      component: () => import(/* webpackChunkName: "about" */ "../views/Login")
    }
  ]
});
//
import NProgress from "nprogress";
// import StarterPage from "@/views/StarterPage";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
