import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as fb from "../firebase";
import { get_client_list, get_collection } from "@/modules/firebase_helpers";
import * as user_helpers from "../modules/user_data_management";
// import firebase from 'firebase/app';
import router from "../router";
// import { FieldValue } from "@google-cloud/firestore";
// import { timers } from "jquery";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white",
    SidebarBg: "",
    point_map: {},
    userProfile: {},
    user_list: [],
    query_cursor: null,
    temporary_user_data: {},
    main_email: "",
    main_password: "",
    user_logged_in: false,
    attendee_list_key: 0,
    current_user_query: null,
    rooms_list: [],
    client_selection: "",
    client_list: [],
    locations: [],
    csvData: "",
    attendee_groups: [],
    active_reactions: []
  },
  plugins: [createPersistedState()],
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    add_point(state, new_point) {
      // state.point_map = [new_point.uid] = new_point;
      Vue.set(state.point_map, new_point.title, new_point.value);
      // state.point_map = {...state.obj, uid : new_point };
    },

    remove_point(state, uid) {
      Vue.delete(state.point_map, uid);
    },
    replace_points(state, obj) {
      state.point_map = Object.assign({}, state.point_map, obj);
    },
    clear_points(state) {
      state.point_map = Object.assign({}, {});
    },
    set_active_reactions(state, reactions) {
      state.active_reactions = reactions;
    },
    edit_point(state, data) {
      // console.log('uid: ', data.uid, data);
      Vue.set(state.point_map, data.title, data.value);
      state.attendee_list_key += 1;
      this.dispatch("update_points");
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setUserList(state, documentSnapshots) {
      console.log("Setting User List");
      // console.log(documentSnapshots);
      state.user_list = [];
      documentSnapshots.forEach(doc => {
        // console.log(doc.data());
        state.user_list.push(doc.data());
      });
    },
    update_user(state, new_user_data) {
      // console.log("search list: ", this.state.user_list);
      let indexInArray = this.state.user_list.findIndex(element => {
        // console.log(new_user_data.uid);
        if (!element.user_data) return false;
        return element.user_data.uid === new_user_data.uid;
      });
      if (indexInArray >= 0) {
        // console.log("index in array: ", indexInArray);
        Vue.set(this.state.user_list, indexInArray, new_user_data);
      } else {
        this.state.attendee_list_key += 1;
        let oldList = this.state.user_list.slice();
        this.state.user_list = [];
        oldList.forEach(element => {
          this.state.user_list.push(element);
        });
      }
    },
    set_logged_in_state(state, val) {
      this.state.user_logged_in = val;
    },
    update_active_credentials(state, user_data) {
      this.state.main_email = user_data.email;
      this.state.main_password = user_data.password;
    },
    set_room_list(state, data) {
      state.rooms_list = data;
    },
    set_client(state, data) {
      state.client_selection = data;
      console.log(
        "Setting state of client selection, ",
        state.client_selection
      );
    },
    set_locations(state, locations) {
      state.locations = locations;
    },
    remove_location(state, location) {
      let indexOf = state.locations.indexOf(location);
      state.locations.splice(indexOf, 1);
    },
    updateCSVData(state, newCSVData) {
      state.csvData = newCSVData;
    },
    set_groups(state, groups) {
      state.attendee_groups = groups;
    },
    update_target_location(state, location_info) {
      state.locations.splice(location_info.index, 1, location_info.data);
    }
  },
  actions: {
    async update_points({ dispatch }) {
      // let self = this;
      let curPointMap = this.state.point_map;
      try {
        const response = await get_collection(
          this.state.client_selection,
          "points"
        )
          .doc("points")
          .set({ point_map: curPointMap }, { merge: true });
        console.log("Updated points successfully!", response);
        // const new_data = await fb.pointsCollection.doc('points').get();
        // console.log(new_data);
      } catch (error) {
        console.log("Error writing to database: ", error);
      }
    },
    async get_points({ dispatch }) {
      try {
        this.commit("replace_points", {});

        const response = await get_collection(
          this.state.client_selection,
          "points"
        )
          .doc("points")
          .get();
        let tempPointMap = response.data().point_map;
        if (tempPointMap !== null && tempPointMap !== undefined) {
          this.commit("replace_points", response.data().point_map);
          return true;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async login({ dispatch }, form) {
      try {
        const { user } = await fb.auth.signInWithEmailAndPassword(
          form.email,
          form.password
        );
        this.main_email = form.email;
        this.main_password = form.password;
        console.log(user.uid);
        try {
          await dispatch("fetchUserProfile", user);
          return true;
        } catch (error) {
          return false;
        }
      } catch (error) {
        console.log("Inside of login dispatch: ", error);
        return false;
      }
    },
    async logout({ dispatch }) {
      try {
        await fb.auth.signOut();
        console.log("user logged out");
        this.commit("setUserProfile", {});
        this.commit("update_active_credentials", {
          email: "",
          password: ""
        });
        router.push("/login");
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.user_data.doc(user.uid).get();
      console.log(user.uid);
      console.log("user profile: ");
      // set user profile in state
      let newProfile = userProfile.data();
      if (newProfile === undefined) {
        newProfile = { "no data": "no data" };
      }
      commit("setUserProfile", newProfile);

      // // change route to dashboard
      router.push("/");
    },
    async get_user_by_email({ dispatch }, email) {
      let targetCollection = get_collection(
        this.state.client_selection,
        "users"
      );
      const results = await targetCollection
        .where("user_data.event_email", "==", email)
        .get();
      if (results !== null) {
        this.commit("setUserList", results);
      }
    },
    async get_user_by_last_name({ dispatch }, last_name) {
      let targetCollection = get_collection(
        this.state.client_selection,
        "users"
      );
      const results = await targetCollection
        .where("user_data.last_name", "==", last_name)
        .get();
      if (results !== null) {
        this.commit("setUserList", results);
      }
    },
    async fetchUsers({ dispatch }, queryInfo) {
      this.state.current_user_query = queryInfo;
      if (!this.state.client_selection) throw "No Client Selected!";
      try {
        var results = null;
        if (this.state.query_cursor === null) {
          // console.log("order: ", this.state.current_user_query.orderBy);
          let userCollection = fb.db.collection(
            "customers/" + this.state.client_selection + "/users"
          );
          results = await userCollection
            .orderBy(this.state.current_user_query.orderBy)
            .limit(this.state.current_user_query.items_per_page)
            .get();
          if (results !== null) {
            this.commit("setUserList", results);
          }
        } else {
          // results = await fb.user_data
          //   .orderBy(queryInfo.orderBy)
          //   .startAfter(this.query_cursor)
          //   .limit(queryInfo.items_per_page);
          console.log("running with startAfter");
          // if (results !== null) {
          //   this.commit("setUserList", results.data());
          // }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async update_user({ dispatch }, user_data_arr) {
      const fbFunction = fb.fbFunctions.httpsCallable("UpdateUser");
      let customercode = this.state.client_selection;
      let errors = [];
      for (let i = 0; i < user_data_arr.length; i++) {
        let curData = {
          ...user_data_arr[i],
          customercode: customercode
        };
        try {
          const res = await fbFunction(curData);
          if (res.data !== "success") {
            errors.push([curData.event_attendee_id, res.data]);
          }
          console.log("response from update_user: ", res);
          // this.commit("update_user", user_data);
        } catch (error) {
          console.error(error);
          errors.push([curData.event_attendee_id, error]);
        }
      }
    },
    async create_user({ dispatch }, user_data) {
      const fbFunction = fb.fbFunctions.httpsCallable("CreateUser");
      // console.log("within displatch: ", user_data);
      let response = false;
      var res;
      if (!this.state.client_selection) {
        throw "No Client Selected!";
      }
      user_data["customercode"] = this.state.client_selection;
      try {
        let res = await fbFunction(user_data);
        console.log("response from fb: ", res.data);
        if (res.data === "success") {
          console.log(
            "----- SUCCESS FROM USER CREATION CLOUD FUNCTION ---------- ",
            res.data
          );
          response = true;
          this.dispatch("fetchUsers", this.state.current_user_query);
        } else {
          response = false;
          console.error("response didn't contain success, ", res);
        }
      } catch (error) {
        console.error("----- ERROR CREATING USER -------", res);
      }

      return response;
    },
    async batch_create_users({ dispatch }, user_data_arr) {
      const fbFunction = fb.fbFunctions.httpsCallable("CreateUser");
      let response = false;
      if (!this.state.client_selection) {
        throw "No Client Selected!";
      }
      let payload;

      let error_log = [];
      for (let i = 0; i < user_data_arr.length; i++) {
        let curUserData = user_data_arr[i];
        const additional_fields = user_helpers.get_additional_fields(
          user_data_arr[i]
        );
        payload = curUserData;
        payload.customercode = this.state.client_selection;
        payload.email = payload.event_attendee_id + "@nmrgames.com";
        payload.username = payload.first_name + " " + payload.last_name;
        payload.password = payload.event_attendee_id;
        payload.additional_data = additional_fields;
        try {
          const res = await fbFunction(payload);
          console.log("Response from create users: ", res);
          if (res.data !== "success") {
            error_log.push([
              curUserData.event_email,
              payload.event_attendee_id,
              res.data
            ]);
            if (res.data === "The user with the provided uid already exists.") {
              console.log("User exists: update");
              let update_data = Object.assign({}, payload);
              delete update_data.email;
              delete update_data.event_email;
              delete update_data.password;
              const updateFunction = await fb.fbFunctions.httpsCallable(
                "UpdateUser"
              );
              update_data.additionaldata = update_data.additional_data;
              const update_res = await updateFunction(update_data);
              if (update_res.data !== "success") {
                error_log.push([
                  curUserData.event_email,
                  payload.event_attendee_id,
                  res.data
                ]);
              }
            }
          }
          // console.log("Successfully created ", curUserData.event_email);
        } catch (error) {
          error_log.push([
            curUserData.event_email,
            payload.event_attendee_id,
            error
          ]);
        }
      }
      console.log("CONCLUDED IMPORT: ", error_log);
    },
    async delete_user({ dispatch }, users) {
      console.log("Beginning the delete user function: received: ", users);

      var fbFunction;
      if (typeof users === "string") {
        fbFunction = fb.fbFunctions.httpsCallable("deleteUser");
      } else if (Array.isArray(users) === true && users.length > 0) {
        fbFunction = fb.fbFunctions.httpsCallable("deleteUsers");
      }
      let data = {
        uid: users,
        customercode: this.state.client_selection
      };
      try {
        let res = await fbFunction(data);
        console.log("response from fb: ", res.data);
        if (res.data === "success") {
          console.log("----- SUCCESS FROM FB FUNCTION ---------- ", res.data);
          await this.dispatch("fetchUsers", this.state.current_user_query);
        } else {
          throw new Error(res.data);
        }
        return res.data;
      } catch (error) {
        console.log("------ ERROR DELETING USERS ------- \n", error);
        return null;
      }
    },
    async get_rooms({ dispatch }) {
      console.log("Running get rooms");
      let roomsRef = fb.roomsRef.once("value", snapshot => {
        if (snapshot) {
          let data = snapshot.val();
          this.commit("set_room_list", data);
          // console.log(roomsRef, dispatch);
        }
      });
    },
    async get_locations({ dispatch }) {
      console.log(dispatch);
      // const locations = await get_collection(this.state.client_selection, 'config').get();
      const response = await get_collection(
        this.state.client_selection,
        "config"
      ).get();
      let locationsArr = [];
      response.forEach(docSnapshot => {
        let tempDoc = docSnapshot.data();
        tempDoc["url"] = docSnapshot.id;
        tempDoc["pretty_url"] = decodeURIComponent(tempDoc.url);
        locationsArr.push(tempDoc);
      });
      this.commit("set_locations", locationsArr);
    },
    create_location({ dispatch }, location_data) {
      console.log("in create location: ", get_collection);
      let locationCollection = get_collection(
        this.state.client_selection,
        "config"
      );
      let promises = [];
      location_data.forEach((cur_location, index) => {
        let encodedUrl = encodeURIComponent(cur_location.url);
        let docID = encodedUrl;
        promises.push(
          locationCollection.doc(docID).set({
            easter_egg_hunt: {},
            quiz_game: {},
            visit_to_winit: {},
            leaderboard: {},
            social_feed: {},
            reactions: {},
            display_name: cur_location.display_name,
            url: encodedUrl
          })
        );
        Promise.all(promises).then(items => {
          console.log("All location Updated!");
          this.dispatch("get_locations");
        });
      });
      return true;
    },
    async delete_location({ dispatch }, location_arr) {
      let locationCollection = get_collection(
        this.state.client_selection,
        "config"
      );
      let self = this;
      for (let i = 0; i < location_arr.length; i++) {
        let location = location_arr[i];
        locationCollection
          .doc(location.url)
          .delete()
          .then(function() {
            console.log("Location deleted!");
            self.commit("remove_location", location);
          })
          .catch(function(error) {
            console.log("Error deleting location: ", error);
          });
      }
    },
    async basic_location_update({ dispatch }, location_info, indexInArr) {
      let configCollection = get_collection(
        this.state.client_selection,
        "config"
      );

      await configCollection
        .doc(location_info.data.url)
        .set(location_info.data);
      this.commit("update_target_location", location_info, indexInArr);
      // this.dispatch("get_locations");
    },
    async update_location({ dispatch }, data) {
      /**
       * Update function supports 2 types of updates:
       *
       *  - merge, and replace
       *  -
       *  - data = {
       *        id :
       *        payload :  the object we are replacing, the game state
       *        target  :  string, the target subfield (the game)
       *        merge   :  Boolean, should we merge the subfields or delete?
       *      }
       *
       */
      console.log(data);
      let promises = [];
      data.forEach(element => {
        let id = element.id;
        let payload = element.payload;
        let configCollection = get_collection(
          this.state.client_selection,
          "config"
        );
        let updateData = {};
        updateData[element.target] = element.payload;
        let merge = false;
        if (element.merge === true) {
          merge = true;
          promises.push(
            configCollection.doc(id).set(updateData, { merge: merge })
          );
        } else {
          promises.push(
            configCollection.doc(id).update(updateData, { merge: merge })
          );
        }
      });
      Promise.all(promises).then(value => {
        this.dispatch("get_locations");
      });
      //    configuCollection.doc(VOD).update(leaderboard {enabled:true})
    },
    async get_client_list({ dispatch }) {
      let clientDocs = await fb.customer_collection.get();
      let tempClientList = [];
      try {
        clientDocs.forEach(single_doc => {
          tempClientList.push(single_doc.data());
        });
        this.state.client_list = tempClientList;
      } catch (error) {
        console.error(error);
      }
    },
    async create_customer({ dispatch }, data) {
      let customerName = data.name;
      let setData = data;
      await this.dispatch("get_client_list");
      if (this.state.client_list.includes(customerName) === false) {
        fb.customer_collection.doc(customerName).set(setData);
        this.dispatch("get_client_list");
      } else {
        throw "Customer already exists";
      }
    },
    async edit_customer({ dispatch }, data) {
      let customerName = data.name;
      let updateData = data;
      await fb.customer_collection
        .doc(customerName)
        .update(updateData, { merge: true });
      this.dispatch("get_client_list");
    },
    async rank_attendees({ dispatch }) {
      const rank_attendees_function = fb.fbFunctions.httpsCallable(
        "rankAttendees"
      );
      const payload = {
        customercode: this.state.client_selection
      };
      try {
        const res = await rank_attendees_function(payload);
        console.log("response from rank attendees: ", res);
      } catch (error) {
        console.error(error);
      }
    },
    async add_points_to_user({ dispatch }, data) {
      let field_value_type = data.field_value_type;
      let update_type;
      let errorArr = [];
      let points_data = data.data;
      let updated_docs = [];
      let targetCollection = get_collection(
        this.state.client_selection,
        "users"
      );
      let error_log = [];
      this.commit("clear_points");
      const res = await this.dispatch("get_points");
      const current_point_map = this.state.point_map;
      for (let i = 0; i < points_data.length; i++) {
        let current_uid;
        let current_data = points_data[i];
        current_data.multiplier = parseFloat(current_data.value);
        if (current_data.title in current_point_map === false) {
          console.log("Point doesn't exist", current_data.title);
          this.commit("add_point", {
            title: current_data.title,
            value: 1
          });
          await this.dispatch("update_points");
        } else {
          console.log("Point exists");
        }
        let current_email = current_data["event_email"];
        delete current_data["event_email"];
        delete current_data["value"];
        let current_new_point = {};
        current_new_point[current_data.title] = current_data;
        console.log(current_new_point);
        const response = await targetCollection
          .where("user_data.event_email", "==", current_email)
          .get();
        response.forEach(doc => {
          current_uid = doc.id;
          updated_docs.push({
            ...doc.data(),
            uid: doc.id
          });

          // console.log(doc.id, doc.data());
        });

        if (current_uid) {
          if (field_value_type === "union") {
            targetCollection
              .doc(current_uid)
              .set(
                {
                  points: current_new_point
                },
                { merge: true }
              )
              .then(res => {
                // console.res;
              })
              .catch(error => {
                console, error(error);
              });
          } else if (field_value_type === "remove") {
            targetCollection.doc(current_uid).update({
              points: fb.firestore.FieldValue.arrayRemove(current_data)
            });
          }
        } else {
          console.error("User: ", current_email, "does not exist\n\n");
          errorArr.push([current_email, current_data.title]);
        }
      }
      console.log("ERROR EMAILS: ", errorArr);
    },
    async fetch_groups({ dispatch }, query) {
      // orderBy
      //items_per_page

      try {
        let collection = get_collection(
          this.state.client_selection,
          "group_results"
        );
        const res = await collection.get();
        let docs = [];
        res.forEach(doc => {
          console.log(doc);
          docs.push(doc.data());
        });
        console.log("Results from groups collection: ", docs);
        this.commit("set_groups", docs);
      } catch (error) {
        console.log(error);
      }
    },
    async create_groups({ dispatch }, groups_arr) {
      // data.group_id, data.display_name
      const fbFunc = fb.fbFunctions.httpsCallable("CreateGroup");
      let final_data = {
        groups: JSON.stringify(groups_arr),
        customercode: this.state.client_selection
      };
      try {
        const res = await fbFunc(final_data);
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    },
    async edit_group({ dispatch }, group_data) {
      let group_id = group_data.groupId;
      let group_display_name = group_data.display_name;
      let multiplier = group_data.multiplier;
      if (multiplier === undefined) {
        multiplier = 1;
      }
      let collection = get_collection(
        this.state.client_selection,
        "group_results"
      );
      const res = await collection.doc(group_id).set(
        {
          display_name: group_display_name,
          multiplier: multiplier
        },
        { merge: true }
      );
      this.dispatch("fetch_groups");
    }
  },
  getters: {}
});
